<template>
	<v-row v-cloak class="h-100">
		<v-col class="d-flex justify-center align-center">
			<v-card class="rounded-xl elevation-4" width="1000">
				<v-container>
					<v-row>
						<v-col cols="12" md="4">
							<v-card-title class="d-flex justify-center headline pb-0">
								<div class="w-100 text-center font-weight-bold">PONCHES FINCA</div>
								<v-img v-cloak src="@/assets/images/logo.png" max-width="100"></v-img>
							</v-card-title>
						</v-col>
						<v-col>
							<v-card-title class="d-flex justify-center headline">{{ date }} -- {{ time }}</v-card-title>
							<v-card-text class="d-flex justify-center align-start">
								<div class="w-50 mr-3">
									<v-text-field v-model="card_id" class="rounded-lg font-size-lg" ref="card_id" @keyup.enter="submit" :readonly="loading" prepend-inner-icon="mdi-magnify" hint="Id tarjeta" clearable outlined dense persistent-hint></v-text-field>
								</div>
								<v-btn :disabled="loading" @click="submit" class="border rounded-lg font-size-xl" depressed>Ponchar</v-btn>
							</v-card-text>
						</v-col>
					</v-row>
				</v-container>
			</v-card>
		</v-col>
	</v-row>
</template>
<script>
import throttle from 'lodash/throttle'
import { get_current_date } from '@/assets/modules'

export default {
    name: 'Home',
	data: () => ({
		card_id: '',
		date: '',
		time: '',

		loading: false,
		options: { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
	}),
	created() {
		this.date = (new Date()).toLocaleDateString('es-PR', this.options)
		this.time = (new Date()).toLocaleTimeString('en-US')
		setInterval(() => {
			this.date = (new Date()).toLocaleDateString('es-PR', this.options)
			this.time = (new Date()).toLocaleTimeString('en-US')
		}, 1000)
	},
	mounted() {
		this.$refs['card_id'].$refs.input.select()
	},
	methods: {
		get_current_date,
		submit: throttle(function() {
			if (!this.card_id && this.card_id.length <= 10 && !this.loading) return
			this.loading = true
			this.$http.post('/timestamps', this.args).then((resp) => {
                this.$root.$emit('alert', { status: resp.data.status, message: resp.data.message })
				this.card_id = ''
				this.$refs['card_id'].$refs.input.select();
			}).finally(() => {
				this.loading = false
			})
		}, 1500, { trailing: false }),
	},
	computed: {
		args() {
			return { card_id: this.card_id, date: this.get_current_date(), time: this.time }
		}
	}
}
</script>