/**
 *  get current date
 *	@return {String}    - current date in Puerto Rico in ISO format
*/
function get_current_date() {
    return new Date().toLocaleString('sv', {timeZone: 'America/Puerto_Rico'}).substr(0,10)
}

/**
 *  format date
 *	@param  {Date}      - date
 *	@return {String}    - date in mm/dd/yyyy format
*/
function format_date(date) {
    const [year, month, day] = date.split('-')
    return `${month}/${day}/${year}`
}

/**
 *  time to 12hr
 *	@param  {String}    - time
 *	@return {String}    - time in 12hr format
*/
function time_to_12hr(time) {
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time]

    if (time.length > 1) {
        time = time.slice(1)
        time[5] = +time[0] < 12 ? ' AM' : ' PM'
        time[0] = +time[0] % 12 || 12
    }

    return time.join('')
}

function zeroPad (num) { return String(num).padStart(2, '0') }

/**
 *  time to 24hr
 *	@param  {String}    - time
 *	@return {String}    - time in 24hr format
*/
function time_to_24hr (time) {
    const [time_12hr, modifier] = time.split(' ')
    let [hours, minutes, seconds] = time_12hr.split(':')

    if (hours === '12')     hours = '00'
    if (modifier === 'PM')  hours = parseInt(hours, 10) + 12

    return `${zeroPad(hours)}:${zeroPad(minutes)}:${zeroPad(seconds)}`
}

export { get_current_date, format_date, time_to_12hr, time_to_24hr }